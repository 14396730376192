* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: Segoe UI, Segoe UI Web (West European), Segoe, -apple-system,
    BlinkMacSystemFont, Roboto, Helvetica Neue, Tahoma, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

ul {
  list-style-type: none;
}

///////// start fleet list

.ms-List-cell:hover .action-icon,
.ms-List-cell .is-selected .action-icon {
  opacity: 1;
}
.action-icon {
  opacity: 0;
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  font-size: 14px;
  top: 0;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.1s linear;
}

//////////// end fleet list
a {
  color: #000;
  text-decoration: none;
  &:active {
    color: #000;
  }
  &:visited {
    color: #000;
  }
}

.ms-ContextualMenu-link {
  .ms-ContextualMenu-icon.is-disabled {
    color: rgb(161, 159, 157) !important;
  }
}

.Toastify__toast-body {
  margin: 0;
}

.Toastify__toast {
  padding: 0;
  min-height: 0;
  border-radius: 2px;
}
.Toastify__close-button {
  display: none;
}

.Toastify__toast-container {
  width: auto;
  max-width: 500px;
  min-width: 300px;
  z-index: 9999999;
}

.Toastify__progress-bar {
  height: 2px;
}

.Toastify__toast--error {
  .Toastify__progress-bar {
    background-color: #a80000;
  }
}

.Toastify__toast--success {
  .Toastify__progress-bar {
    background-color: #107c10;
  }
}

.Toastify__toast--info {
  .Toastify__progress-bar {
    background-color: #605e5c;
  }
}

.ms-TextField-fieldGroup input,
.ms-TextField-fieldGroup textarea {
  &:disabled {
    background: white;
  }
}

// Phrase in-context editor should be at the top
div[class*=pi_]{
  z-index: 2000003 !important;

}



.analytics-report-class {
  width: 100%;
  height: calc(100vh - 50px - 100px);
}

.analytics-report-class iframe {
  border: 0px;
  background-color: #e4f9fe;
}

ul,
ol {
  padding-left: 40px;
}

.displayAreaContainer {
  background-color: #e4f9fe;
}

.canvasFlexBox {
  background-color: #e4f9fe;
}

/*
SVG Timeline
*/
svg-class {
  background-color: white;
}
.svg-background {
  background-color: white;
}
.marks .land {
  fill: #7a91b3;
}
.marks .sphere {
  fill: #fbfbfb;
}
.marks .interiors {
  fill: none;
  stroke: #d9dfe0;
}
circle {
  fill: #137b80;
  opacity: 0.3;
}
.marks .graticules,
.tick line {
  fill: none;
  stroke: #d9d9d9;
}
.tick text {
  fill: #635f5d;
  font-size: 0.8em;
}
.marksdark .graticulesdark,
.tickdark line {
  fill: none;
  stroke: #464646;
}
.darkfill {
  fill: white;
}
.darkstroke {
  stroke: white;
}
.tickdark text {
  fill: white;
  font-size: 0.8em;
}
.mark {
  opacity: 1;
  cursor: pointer;
}
.mark:hover {
  opacity: 0.7;
}
.mark_plain {
  opacity: 1;
}
.mark_plain:hover {
  opacity: 0.7;
}

.status {
  opacity: 1;
  cursor: normal;
}
.status-point {
  fill: white;
  opacity: 1;
  cursor: pointer;
}
.status-point-red {
  fill: #a4262c;
  opacity: 1;
  cursor: pointer;
}
.status-point-green {
  fill: #0b6a0b;
  opacity: 1;
  cursor: pointer;
}


.policy-status-point-pending {
  fill: #D44D5C;
  opacity: 1;
  cursor: pointer;
}
.policy-status-point-waiting-for-checking {
  fill: #7b99ad;
  opacity: 1;
  cursor: pointer;
}
.policy-status-point-waiting-for-client {
  fill: #FFD6C0;
  opacity: 1;
  cursor: pointer;
}
.policy-status-point-waiting-for-insurer {
  fill: #3C4F76;
  opacity: 1;
  cursor: pointer;
}
.policy-status-point-waiting-for-registration {
  fill: #AEC5EB;
  opacity: 1;
  cursor: pointer;
}
.policy-status-point-waiting-for-user {
  fill: #FFF689;
  opacity: 1;
  cursor: pointer;
}
.policy-status-point-closed {
  fill: #77966D;
  opacity: 1;
  cursor: pointer;
}
.bookingCircle {
  fill: #ffffff;
  opacity: 1;
  stroke: #888888;
  stroke-width: 1.5;
}

.redBookingLine {
  stroke: #bb0000 !important;
}

.greenBookingLine {
  stroke: #008800 !important;
}
.booking-point-incorrect {
  fill: rgb(254, 217, 204);
  opacity: 1;
}
.booking-point-pending {
  fill: rgb(255, 255, 255);
  opacity: 1;
  stroke-width: 1;
}
.booking-point-correct {
  fill: #ffffff;
  opacity: 1;
}
.booking-point-notyetbooked {
  fill: #d0d0d0;
  opacity: 1;
}
.status-point-gray {
  fill: lightgray;
  opacity: 1;
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}
.clickable:hover {
  opacity: 0.7;
}

.suspension {
  opacity: 1;
  cursor: pointer;
}
.suspension:hover {
  opacity: 0.7;
}
.void {
  fill: gray;
  cursor: pointer;
  opacity: 0;
}
.void:hover {
  opacity: 0.25;
}
.void_shared {
  fill: gray;
  opacity: 0;
}
.void_shared:hover {
  opacity: 0.25;
}
.boldText {
  font-weight: normal;
}
.axis-label {
  font-size: 0.8em;
  fill: #635f5d;
}
.background {
  fill: #ecebe9;
}
.backgrounddark {
  fill: #333333;
}
.row {
  display: flex;
}

.row > div {
  margin: 0 16px;
}

.raquo {
  fill: #635f5d;
  font-size: 1.8em;
  font-weight: lighter;
  cursor: pointer;
}

.column {
  flex: 50%;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 20px;
  height: 20px;

  & .path {
    stroke: hsl(210, 70, 75);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

.transparent_segment {
  opacity: 0.6;
}

// #general-vehicle-form,
// #vehicle-form,
// #finance-vehicle-form,
// #booking-vehicle-form {
//   width: 1000px;
// }
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
/**/
.insuranceLineDetails-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.insuranceLineDetails-table th,
.insuranceLineDetails-table td {
  padding: 8px;
  text-align: left;
  width: 500px;
}

.insuranceLineDetails-table th {
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  font-weight: normal;  
}

.insuranceLineDetails-table input {
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
}
.tag {
  background-color: #f4f4f4;
  border: 1px solid #e9e9e9;
  padding: 2px 0 2px 2px;
  border-radius: 2px;
  display: none; /*inline-block;*/
}
.tag:focus-within {
  background-color: #e9e9e9;
  border-color: #a0a0a0;
}
.tag-remove {
  color: #a0a0a0;
  font-size: 75%;
  line-height: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}
.tag-remove.disabled,
.tag-remove.readOnly {
  cursor: not-allowed;
}
.tag-remove:focus {
  color: #3c3c3c;
}
.node > label {
  cursor: pointer;
  margin-left: 2px;
}
.tag-list {
  display: inline;
  padding: 0;
  margin: 0;
}
.tag-item {
  display: inline-block;
  /*margin-left: 6px;*/
  /*margin-right: 4px;*/
  font-size: 14px;
}
.tag-item .search {
  border: none;
  /*border-bottom: 1px solid #ccc;*/
  outline: none;
  font-family: Segoe UI, Segoe;
  background-color: transparent;
}

.dark .tag-item .search {
  color: white;
}

.dark .tag-item .search::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: lightgray;
  opacity: 1; /* Firefox */
}

.dark .tag-item .search:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: lightgray;
}

.dark .tag-item .search::-ms-input-placeholder { /* Microsoft Edge */
  color: lightgray;
}

.tag-item:last-child {
  margin-right: 4px;  
}
.node {
  list-style: none;
  white-space: nowrap;
  padding: 4px;
}
.node.leaf.collapsed {
  display: none;
}
.node.disabled > * {
  color: gray;
  cursor: not-allowed;
}
.node.match-in-children.hide .node-label {
  opacity: 0.5;
}
.node.focused {
  background-color: #f4f4f4;
}
.dark > .dropdown > .dropdown-content > .root > div > .infinite-scroll-component > .node.focused {
  background-color: #4f4f4f;
}
.toggle {
  white-space: pre;
  margin-right: 4px;
  cursor: pointer;
}
.toggle:after {
  content: " ";
}
.toggle.collapsed:after {
  content: "+";
}
.toggle.expanded:after {
  content: "-";
}
.searchModeOn .toggle {
  display: none;
}
.checkbox-item,
.radio-item {
  vertical-align: middle;
  margin: 0 4px 0 0;
}
.checkbox-item.simple-select,
.radio-item.simple-select {
  display: none;
}
.hide:not(.match-in-children) {
  display: none;
}
.react-dropdown-tree-select .dropdown {
  position: relative;
  display: table;
  height: 32px;
}
.dark > .dropdown {
  background-color: black;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
  padding: 4px;
  line-height: 20px;
  max-height: 200px;  
  display: inline-block;
  overflow: auto;
  border: 1px solid #b9b9b9;
  border-radius: 2px;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
  cursor: pointer;
  height: 32px;
  overflow: hidden;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
  content: "\203A";
  vertical-align: middle;
  color: #3c3c3c;
  margin-right: 2px;
  display: inline-block;
  transform: rotate(90deg);
  font-size: 32px;
  color: #605e5c;
  font-weight: 100;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  content: "\203A";
  vertical-align: middle;
  color: #3c3c3c;
  margin-right: 2px;
  display: inline-block;
  transform: rotate(90deg);
  font-size: 32px;
  color: #605e5c;
  font-weight: 100;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled {
  cursor: not-allowed;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled.bottom:after {
  color: #b9b9b9;
}
.react-dropdown-tree-select .dropdown .dropdown-content {
  position: absolute;
  padding: 4px;
  z-index: 1;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  overflow-y: scroll;
  height: 400px;
}
.dark > .dropdown .dropdown-content {
  background-color: #000 !important;
  color: white;
}
.react-dropdown-tree-select .dropdown .dropdown-content .search {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
}
.react-dropdown-tree-select .dropdown .dropdown-content ul {
  margin: 0;
  padding: 0;
}

/*# sourceMappingURL=styles.css.map*/

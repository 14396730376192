@import "~office-ui-fabric-react/dist/sass/References.scss";

.accordion {
  background: #333333;
  margin: 10px;

  & > button {    
    min-height: 44px;
    border: 2px solid transparent;
    border-radius: 0;
    outline: 1px solid transparent;
    outline-offset: -3px;
    background: #ffffff; // Microsoft web site doesn't use Fabric colors!
    color: rgba(0, 0, 0, 0.8); // Microsoft web site doesn't use Fabric colors!
    text-align: left;
    width: 100%;
    //border-bottom: 1px solid rgba(0, 0, 0, 0.2); // Microsoft web site doesn't use Fabric colors!

    :global(.ms-Button-label) {
      font-weight: 400;
    }

    &:hover,
    &:focus {
      border-radius: 0;
      //box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.16); // Microsoft web site doesn't use Fabric colors!
      color: $ms-color-black;
      background: #ffffff; // Microsoft web site doesn't use Fabric colors!
    }

    &:focus {
      border-radius: 0;
      border-width: 2px;
      border-style: solid;
      border-color: $ms-color-black;
      outline-color: rgba(255, 255, 255, 0.6); // Microsoft web site doesn't use Fabric colors!
    }
  }

  .drawer {
    background: #ffffff; // Microsoft web site doesn't use Fabric colors!
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }
}

.accordionChevron {
  font-size: 16px;
  margin-left: -6px;
  color: rgba(0, 0, 0, 0.8);
}

.accordionDark {
  background: #333333;
  margin: 10px;

  & > button {
    min-height: 44px;
    border: 2px solid transparent;
    outline: 1px solid transparent;
    outline-offset: -3px;
    background: rgba(0, 0, 0, 0.8); // Microsoft web site doesn't use Fabric colors!
    color: #f0f0f0; // Microsoft web site doesn't use Fabric colors!
    text-align: left;
    width: 100%;
    border-bottom: 1px solid rgba(23, 23, 23, 0.2); // Microsoft web site doesn't use Fabric colors!

    :global(.ms-Button-label) {
      font-weight: 400;
    }

    &:hover,
    &:focus {
      box-shadow: 0 0 4px 2px rgba(200, 200, 200, 0.16); // Microsoft web site doesn't use Fabric colors!
      color: #f0f0f0;
      background: #171717; // Microsoft web site doesn't use Fabric colors!
    }

    &:focus {
      border-width: 2px;
      border-style: solid;
      border-color: #f0f0f0;
      outline-color: #171717; // Microsoft web site doesn't use Fabric colors!
    }
  }

  .drawer {
    background: #171717; // Microsoft web site doesn't use Fabric colors!
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }
}

.accordionChevronDark {
  font-size: 16px;
  margin-left: -6px;
  color: #f0f0f0;
}